import React from 'react';
import { Body, MainContainer } from '../../styles';

const ContentWithBlank = () => {
  return (
    <MainContainer>
      <Body></Body>
    </MainContainer>
  );
};
export default ContentWithBlank;
